export default function (authors, isTitle = false) {
  let writers = authors.filter((a) => a.role !== "translator");
  let writerNames = writers.map((a) => {
    return `${a.author.data.first_name.text} ${a.author.data.last_name.text}`;
  });

  let translators = authors.filter((a) => a.role === "translator");
  let translatorNames = translators.map((a) => {
    return `${a.author.data.first_name.text} ${a.author.data.last_name.text}`;
  });

  let writerStr =
    writerNames?.length === 1
      ? writerNames[0]
      : `${writerNames
          .map(
            (n, idx) =>
              `${
                idx > 0 && idx !== writerNames.length - 1
                  ? `,<br/>`
                  : idx === writerNames.length - 1
                  ? ` and<br/>`
                  : ``
              }${n}`
          )
          .join("")}`;

  let str = writerStr;

  if (translators?.length > 0) {
    if (translatorNames.length > 0) {
      str = `${str}<br/><span style="font-weight:normal; margin-top:0.25em; ${
        isTitle ? "font-size:0.6em; line-height:1.2em;" : ""
      } display:inline-block;">Translated by</span><br/><span style="display:inline-block; ${
        isTitle ? "font-size:0.6em; line-height:1.2em;" : ""
      } ">`;
    }
    str = `${str}${
      translatorNames.length === 1
        ? translatorNames[0]
        : `${translatorNames
            .map(
              (n, idx) =>
                `${
                  idx > 0 && idx !== translatorNames.length - 1
                    ? `,<br/>`
                    : idx === translatorNames.length - 1
                    ? ` and<br/>`
                    : ``
                }${n}`
            )
            .join("")}</span>`
    }`;
  }

  return str;
}
